import { useFormik } from 'formik'
import * as Yup from 'yup'
import Editor from 'react-froala-wysiwyg'
import Select from 'react-select'
import {
  colourStyles,
  convertToReactSelectObject,
  techOptions,
} from './utlis/TechOptions'
import { useDispatch, useSelector } from 'react-redux'
import { setStep1 } from '../../store/buyerProject/buyerProjectReducer'
import { RootState } from '../../store'
import _ from 'lodash'

const StepOne: React.FC = () => {
  const dispatch = useDispatch()
  const step1Data = useSelector(
    (state: RootState) => state.buyerProjects.step1Data
  )

  const config = {
    key: 'hWA2C-7F1A4A4B4C2D1vd1EAJLQCVLUVBa1NXNRSSATEXD-13C3B2E2G2E3B1C6C7D2D2==',
    attribution: false,
    heightMin: 200,
    placeholderText: 'Describe your requirements',
    inlineMode: false,
    pastePlain: true,
    htmlRemoveTags: ['script'],
    pasteDeniedAttrs: ['style'],
  }

  const formSchema = Yup.object().shape({
    title: Yup.string().required('Project title is required'),
    description: Yup.string().required('Project description is required'),
    price: Yup.number().required('Price is required'),
    primaryTech: Yup.array()
      .min(1)
      .required('Your projects secondary technology is required'),
    secondaryTech: Yup.array()
      .min(1)
      .required('Your projects secondary technology is required'),
  })

  const formik = useFormik({
    initialValues: _.isEmpty(setStep1)
      ? {
          title: '',
          description: '',
          price: '',
          primaryTech: [],
          secondaryTech: [],
        }
      : step1Data,

    validationSchema: formSchema,
    onSubmit: (values) => {
      dispatch(setStep1(values))
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="mt-5">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700 dark:text-white"
          >
            Project title
          </label>
          <div className="mt-2">
            <input
              type="text"
              // name="title"
              id="title"
              {...formik.getFieldProps('title')}
              className="block py-4 w-full rounded-md border-gray-300 dark:bg-gray-950 dark:text-white shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
              placeholder="For example: I am looking for a Hubspot Developer"
            />
            {formik.errors.title && formik.touched.title ? (
              <span className="text-red-500 text-sm">
                {String(formik.errors.title)}
              </span>
            ) : null}
          </div>
        </div>
        <div className="mt-5">
          <label
            htmlFor="comment"
            className="block text-sm font-medium text-gray-700 dark:text-white"
          >
            Describe your requirements
          </label>
          <div className="mt-2">
            <Editor
              tag="textarea"
              model={formik.values.description}
              onModelChange={(value) =>
                formik.setFieldValue('description', value)
              }
              config={config}
            />
            {formik.errors.description && formik.touched.description ? (
              <span className="text-red-500 text-sm">
                {String(formik.errors.description)}
              </span>
            ) : null}
          </div>
        </div>
        <div className="mt-5">
          <label
            htmlFor="price"
            className="block text-sm font-medium text-gray-700 dark:text-white"
          >
            Do you have a specific budget for this project?
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              // name="price"
              id="price"
              {...formik.getFieldProps('price')}
              className="block w-full rounded-md py-4 border-gray-300 dark:bg-gray-950 dark:text-white pl-7 pr-12 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
              placeholder="0.00"
              aria-describedby="price-currency"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USD
              </span>
            </div>
          </div>
          {formik.errors.price && formik.touched.price ? (
            <span className="text-red-500 text-sm">
              {String(formik.errors.price)}
            </span>
          ) : null}
        </div>
        <div className="mt-5">
          <label
            htmlFor="location"
            className="block text-sm font-medium text-gray-700 dark:text-white"
          >
            Please indicate your project&apos;s main technology requirement
          </label>
          <Select
            isMulti
            name="colors"
            options={convertToReactSelectObject(techOptions)}
            value={formik?.values?.primaryTech?.map((x: any) => ({
              value: x,
              label: x,
            }))}
            onChange={(value) => {
              formik.setFieldValue(
                'primaryTech',
                value.map((x: any) => x.value)
              )
            }}
            styles={colourStyles}
            className="basic-multi-select"
            classNamePrefix="select"
            // menuIsOpen={true}
          />
          {formik.errors.primaryTech && formik.touched.primaryTech ? (
            <span className="text-red-500 text-sm">
              {String(formik.errors.primaryTech)}
            </span>
          ) : null}
        </div>
        <div className="mt-5">
          <label
            htmlFor="location"
            className="block text-sm font-medium text-gray-700 dark:text-white"
          >
            Please indicate rest of your project&apos;s technology requirements
          </label>

          <Select
            isMulti
            name="colors"
            options={convertToReactSelectObject(techOptions)}
            value={formik?.values?.secondaryTech?.map((x: any) => ({
              value: x,
              label: x,
            }))}
            onChange={(value) => {
              formik.setFieldValue(
                'secondaryTech',
                value.map((x: any) => x.value)
              )
            }}
            styles={colourStyles}
            className="basic-multi-select"
            classNamePrefix="select"
            // menuIsOpen={true}
          />

          {formik.errors.secondaryTech && formik.touched.secondaryTech ? (
            <span className="text-red-500 text-sm">
              {String(formik.errors.secondaryTech)}
            </span>
          ) : null}
        </div>
        <div className="mt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={formik.handleReset}
              className="rounded-md border border-purple-600 bg-white dark:bg-purple-600 dark:text-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
            >
              Clear
            </button>
            <button
              type="submit"
              disabled={!formik.dirty || formik.isSubmitting}
              className={
                formik.dirty
                  ? 'ml-3 inline-flex justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2'
                  : 'ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled: cursor-not-allowed bg-gray-300'
              }
            >
              Save & proceed
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
export default StepOne

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

function GuestLayout({ children }) {
  const { isDark } = useSelector((state: RootState) => state.theme)
  const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches

  useEffect(() => {
    if (isDark || systemTheme) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDark])

  return (
    <>
      <div>{children}</div>
    </>
  )
}

export default GuestLayout

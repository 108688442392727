import { Fragment } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import SellerRoutes from './pages/Seller/Routes/Index'
import BuyerRoutes from './pages/Buyer/Routes/Index'
import GuestRoutes from './pages/Guests/Routes/Index'
import NotFound from './components/Errors/NotFound/Index'
import AdminRoutes from './pages/Admin/Routes/Index'

// Updated App.tsx

const App = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Navigate to="/auth/login" />} />
        <Route path="/auth/*" element={<GuestRoutes />} />
        <Route path="/buyer/*" element={<BuyerRoutes />} />
        <Route path="/seller/*" element={<SellerRoutes />} />
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Fragment>
  )
}
export default App

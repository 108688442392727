import { Link } from 'react-router-dom'

const BreadCrumb = (props) => {
  return (
    <div className="sticky top-0 flex h-20 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white dark:bg-gray-950 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <div className="flex items-center">
        {props.url ? (
          <Link className="" to={props?.url}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8 dark:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </Link>
        ) : null}
        <h1 className="text-2xl font-bold dark:text-white text-gray-700 ml-4">
          {props?.title}
        </h1>
      </div>
    </div>
  )
}
export default BreadCrumb

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, PaperClipIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import he from 'he'
import DOMPurify from 'dompurify'

const SidePanel = ({ isOpen, setIsOpen, project }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 dark:bg-opacity-15 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                  <div className="flex h-full flex-col overflow-y-scroll dark:bg-gray-950 dark:border dark:border-white bg-white py-6 shadow-xl">
                    {/* Header Section */}
                    <div className="flex items-start justify-between px-4 sm:px-6">
                      <Dialog.Title className="text-xl font-semibold leading-6 dark:text-white text-gray-900">
                        Project Details
                      </Dialog.Title>
                      <button
                        type="button"
                        className="rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500"
                        onClick={() => setIsOpen(false)}
                      >
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        <span className="sr-only">Close panel</span>
                      </button>
                    </div>

                    {/* Project Title */}
                    <div className="px-4 sm:px-6 py-4">
                      <h2 className="text-2xl font-semibold dark:text-white text-gray-900">
                        {project?.title || 'Project Title Unavailable'}
                      </h2>
                    </div>

                    {/* Project Details */}
                    <div className="px-4 sm:px-6 space-y-6">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-gray-700 dark:text-white">
                        <div>
                          <h4 className="font-semibold">Budget</h4>
                          <p>${project?.budget || 'N/A'} USD</p>
                        </div>
                        <div>
                          <h4 className="font-semibold">Duration</h4>
                          <p>{project?.duration || 'N/A'}</p>
                        </div>
                        <div>
                          <h4 className="font-semibold">Status</h4>
                          <p>{project?.status || 'N/A'}</p>
                        </div>
                        {project?.attachment && (
                          <div>
                            <h4 className="font-semibold">Attachment</h4>
                            <a
                              href={project.attachment}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-purple-600 hover:underline inline-flex items-center"
                            >
                              <PaperClipIcon className="h-5 w-5 mr-2" />
                              Download Attachment
                            </a>
                          </div>
                        )}
                      </div>

                      {/* Project Description */}
                      <div className="text-md dark:text-white text-gray-700 space-y-4">
                        <h4 className="font-semibold">Project Description</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              he.decode(project?.description || 'N/A')
                            ),
                          }}
                        />
                      </div>
                    </div>

                    {/* Apply Section */}
                    <div className="mt-auto px-4 sm:px-6 py-4 bg-gray-50 dark:bg-gray-800">
                      <div className="flex justify-end">
                        {project?._id && (
                          <Link
                            to={`/seller/projects/${project._id}/proposals/apply`}
                            className="rounded-md bg-purple-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-600"
                          >
                            Apply Now
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SidePanel

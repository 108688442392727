import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import Spinner from '../../Spinner/Index'
import TopBar from '../TopBar/Index'
import CookiePolicy from '../CookieConsent/Index'
import MobNav from '../MobNav/Index'
import Navbar from '../Navbar/Index'
import Layout from '../Index'
import { getMe } from '../../../store/auth/authReducer'

function AdminLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isAdmin, setIsAdmin] = useState(true)
  const dispatch = useDispatch()
  const [cookie] = useCookies(['cookieConsent', 'announcementBanner'])
  const { isLoading } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(getMe() as any)
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <Layout>
        {/* Mobile nav sidebar for mobile */}
        <MobNav
          isAdmin={isAdmin}
          setIsAdmin={setIsAdmin}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        {/* Static sidebar for desktop */}
        <Navbar isAdmin={isAdmin} isSeller={false} />
        <div className="lg:pl-72">
          <TopBar
            isAdmin={isAdmin}
            setIsAdmin={setIsAdmin}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <main>{children}</main>
          {!cookie.cookieConsent && <CookiePolicy />}
          {/* {!cookie.announcementBanner && !isOpen && <Banner setIsOpen={setIsOpen} />} */}
          {/* <ChatBot /> */}
        </div>
      </Layout>
    </>
  )
}

export default AdminLayout

import { RadioGroup } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCurrentStep,
  setStep2,
} from '../../store/buyerProject/buyerProjectReducer'
import { RootState } from '../../store'
import _ from 'lodash'

interface ITasks {
  value: string
  id: string
}

interface IMilestone {
  value: string
  id: string
  tasks?: ITasks[]
}

const memoryOptions = [
  { name: 'All', inStock: true },
  { name: 'CRM', inStock: true },
  { name: 'Ecommerce', inStock: true },
  { name: 'Website Building', inStock: true },
  { name: 'Marketing Automation', inStock: true },
  { name: 'Integration', inStock: true },
]

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ')
}

const StepTwo = () => {
  const dispatch = useDispatch()
  const step2Data = useSelector(
    (state: RootState) => state.buyerProjects.step2Data
  )

  const [milestones, setMilestones] = useState<Array<IMilestone>>([
    {
      value: '',
      id: uuid(),
      tasks: [],
    },
    {
      value: '',
      id: uuid(),
      tasks: [],
    },
    {
      value: '',
      id: uuid(),
      tasks: [],
    },
  ])

  useEffect(() => {
    if (step2Data?.milestones?.length > 0) {
      setMilestones(step2Data.milestones)
    }
  }, [step2Data?.milestone?.length])

  const formSchema = Yup.object().shape({
    type: Yup.string().required('Project suggestions are Required'),
  })

  const formik = useFormik({
    initialValues: {
      type: step2Data.type ? step2Data.type : '',
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      const data = {
        type: values.type,
        milestones: milestones,
      }
      dispatch(setStep2(data))
    },
  })

  // Handlers

  const handleAddMilestone = () => {
    setMilestones([
      ...milestones,
      {
        value: '',
        id: uuid(),
        tasks: [],
      },
    ])
  }
  const handleRemoveMilestone = (id: string) => {
    const filteredMilestones = milestones.filter((x) => x.id !== id)
    setMilestones(filteredMilestones)
  }

  const handleAddTasks = (id: string) => {
    const tempMilestones = milestones.map((x) => {
      return _.cloneDeep(x)
    })
    const index = tempMilestones.findIndex((x) => x.id === id)
    const tasks = [...tempMilestones[index].tasks]
    tempMilestones[index].tasks = [
      ...tasks,
      {
        value: '',
        id: uuid(),
      },
    ]
    setMilestones(tempMilestones)
  }

  const handleRemoveTask = (milestoneId: string, taskId: string) => {
    const tempMilestones = milestones.map((x) => {
      return _.cloneDeep(x)
    })
    const index = tempMilestones.findIndex((x) => x.id === milestoneId)

    tempMilestones[index].tasks = tempMilestones[index].tasks?.filter(
      (x) => x.id !== taskId
    )
    setMilestones(tempMilestones)
  }

  const handleBack = () => {
    dispatch(setCurrentStep(1))
  }

  const handleChangeMilestone = (
    e: React.ChangeEvent<HTMLInputElement>,
    milestoneId: string
  ) => {
    const tempMilestones = milestones.map((x) => {
      return _.cloneDeep(x)
    })
    const index = tempMilestones.findIndex((x) => x.id === milestoneId)
    tempMilestones[index].value = e.target.value
    setMilestones(tempMilestones)
  }

  const handleChangeTasks = (
    e: React.ChangeEvent<HTMLInputElement>,
    milestoneId: string,
    taskId: string
  ) => {
    const tempMilestones = milestones.map((x) => {
      return _.cloneDeep(x)
    })
    const index = tempMilestones.findIndex((x) => x.id === milestoneId)
    const taskIndex = tempMilestones[index]?.tasks?.findIndex(
      (x) => x.id === taskId
    )
    tempMilestones[index].tasks[taskIndex].value = e.target.value
    setMilestones(tempMilestones)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mt-5">
        <label
          htmlFor="title"
          className="block text-sm font-medium dark:text-white text-gray-700"
        >
          Customize suggestions for scoping tool below
        </label>

        <div>
          <RadioGroup
            value={formik.values.type}
            onChange={(value: any) => {
              formik.setFieldValue('type', value)
            }}
            className="mt-2"
          >
            <RadioGroup.Label className="sr-only">
              {' '}
              Choose a memory option{' '}
            </RadioGroup.Label>
            <div className="inline-flex">
              {memoryOptions.map((option) => (
                <RadioGroup.Option
                  key={option.name}
                  value={option.name}
                  className={({ active, checked }) =>
                    classNames(
                      active ? 'ring-2 ring-offset-2 ring-purple-500' : '',
                      checked
                        ? 'outline-none ring-2 ring-purple-500 ring-offset-2 cursor-pointer'
                        : 'bg-white dark:bg-purple-600 dark:border-none border-gray-200 text-gray-900 hover:bg-gray-50 cursor-pointer',
                      'mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
                    )
                  }
                  disabled={!option.inStock}
                >
                  <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          {formik.errors.type && formik.touched.type ? (
            <span className="text-red-500 text-sm">{formik.errors.type}</span>
          ) : null}
        </div>
      </div>
      {milestones.map((milestone: any) => {
        return (
          <div className="mt-5" key={milestone.id}>
            <div className="flex justify-between items-center">
              <div className="flex-1">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium dark:text-white text-gray-700"
                >
                  Milestone name
                </label>
              </div>
              <div className="flex">
                <button
                  onClick={() => handleRemoveMilestone(milestone.id)}
                  className="block mr-2 text-sm font-medium text-red-500"
                  type="button"
                >
                  Remove
                </button>
                <button
                  onClick={() => handleAddTasks(milestone.id)}
                  className="block text-sm font-medium text-purple-500"
                  type="button"
                >
                  + Add Task
                </button>
              </div>
            </div>
            <div className="mt-1">
              <input
                type="text"
                name="title"
                id="title"
                value={milestone?.value}
                className="block w-full py-4 rounded-md border-gray-300 dark:bg-gray-950 dark:text-white shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                placeholder="For example: I am looking for a wordpress developer to migrate our blog"
                onChange={(e) => handleChangeMilestone(e, milestone.id)}
              />
            </div>
            {/* tasks */}
            {milestone.tasks.map((task: any) => {
              return (
                <div className="mt-5 ml-8" key={task.id}>
                  <div className="flex justify-between items-center">
                    <div className="flex-1">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium dark:text-white text-gray-700"
                      >
                        Task name
                      </label>
                    </div>
                    <div className="flex">
                      <button
                        onClick={() => handleRemoveTask(milestone.id, task.id)}
                        className="block mr-2 text-sm font-medium text-red-500"
                        type="button"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="title"
                      id="title"
                      value={task?.value}
                      className="block w-full py-4 rounded-md border-gray-300 dark:bg-gray-950 dark:text-white shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder="For example: I am looking for a wordpress developer to migrate our blog"
                      onChange={(e) =>
                        handleChangeTasks(e, milestone.id, task.id)
                      }
                    />
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}

      <div className="mt-5">
        <button
          onClick={handleAddMilestone}
          className="block mr-2 text-sm font-medium text-purple-500"
          type="button"
        >
          + Add another milestone
        </button>
      </div>

      <div className="mt-5">
        <div className="flex justify-end">
          <button
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="submit"
            disabled={!formik.dirty || formik.isSubmitting}
            className={
              formik.dirty
                ? 'ml-3 inline-flex justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2'
                : 'ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled: cursor-not-allowed bg-gray-300'
            }
          >
            Save & proceed
          </button>
        </div>
      </div>
    </form>
  )
}
export default StepTwo

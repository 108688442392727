import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Cog6ToothIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import NavLinks from '../NavLinks/Index'
import SubNavLinks from '../SubNavLinks/Index'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

const MobNav = ({ sidebarOpen, setSidebarOpen, isSeller, isAdmin }) => {
  const { user } = useSelector((state: RootState) => state.auth)

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 lg:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-950 px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  {isAdmin ? (
                    <Link to={`/admin/`}>
                      <span className="text-2xl py-2 font-bold text-purple-500 dark:text-white">
                        CRM Gurus
                      </span>
                    </Link>
                  ) : isSeller ? (
                    <Link to={`/seller/${user?.username}`}>
                      <span className="text-2xl py-2 font-bold text-purple-500 dark:text-white">
                        CRM Gurus
                      </span>
                    </Link>
                  ) : (
                    <Link to={`/buyer/${user?.username}`}>
                      <span className="text-2xl py-2 font-bold text-purple-500 dark:text-white">
                        CRM Gurus
                      </span>
                    </Link>
                  )}
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul className="-mx-2 space-y-1">
                        <NavLinks isAdmin={isAdmin} isSeller={isSeller} />
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">
                        Your teams
                      </div>
                      <ul className="-mx-2 mt-2 space-y-1">
                        <SubNavLinks />
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <Link
                        to="/buyer/settings"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 dark:text-white text-gray-700 hover:bg-gray-50 dark:hover:bg-purple-900 hover:text-purple-600"
                      >
                        <Cog6ToothIcon
                          className="h-6 w-6 shrink-0 text-gray-400 dark:text-white dark:group-hover:text-white group-hover:text-purple-600"
                          aria-hidden="true"
                        />
                        Settings
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default MobNav

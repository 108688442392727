// Layout.jsx
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../store'
import {
  disconnectSocket,
  setSocket,
  userLoggedIn,
} from '../../store/socket/socketReducer'
import socket from '../../utils/socket' // Import the socket

function Layout({ children }) {
  const { isDark } = useSelector((state: RootState) => state.theme)
  const { user } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches

  useEffect(() => {
    socket.on('connect', () => {
      socket.emit('joined', 'Hello from React Client')
      dispatch(setSocket(socket.id))
    })

    socket.on('disconnect', () => {
      console.log('disconnected')
      dispatch(disconnectSocket(null))
    })

    socket.on('userLoggedIn', (data) => {
      dispatch(userLoggedIn(data)) // Dispatch userLoggedIn action with user data
    })

    // Clean up the socket when the component unmounts
    return () => {
      socket.off('connect')
      socket.off('disconnect')
      socket.off('userLoggedIn')
    }
  }, [])

  useEffect(() => {
    if (!(user as { isVerified?: boolean })?.isVerified) {
      navigate(`/auth/verify-email`)
    }

    if (systemTheme) {
      document.documentElement.classList.remove('dark')
    }

    if (isDark) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDark])

  return children
}

export default Layout

import { SpinnerRoundOutlined } from 'spinners-react'

const Spinner = ({ children }) => {
  return (
    <div className="h-screen flex justify-center items-center">
      <div role="status">
        <SpinnerRoundOutlined
          size={150}
          className="mx-auto"
          color={'#a855f7'}
        />
        <p className="text-lg font-medium">{children ? children : null}</p>
      </div>
    </div>
  )
}
export default Spinner

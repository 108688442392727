import {
  ChatBubbleBottomCenterIcon,
  EnvelopeIcon,
  FolderIcon,
  FolderOpenIcon,
  HomeIcon,
  InboxIcon,
  SignalIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { RootState } from '../../../store'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NavLinks = ({ isSeller, isAdmin }) => {
  const { user } = useSelector((state: RootState) => state.auth)

  const sellerNavigation = [
    {
      name: 'Dashboard',
      href: `/seller/${user?.username}`,
      icon: HomeIcon,
      current:
        window.location.pathname === `/seller/${user?.username}` ? true : false,
      isSeller: true,
    },
    {
      name: 'Projects',
      href: `/seller/projects?recent=true`,
      icon: FolderIcon,
      current: window.location.pathname === `/seller/projects` ? true : false,
      isSeller: true,
    },
    {
      name: 'Leads',
      href: `/seller/leads`,
      icon: InboxIcon,
      current: window.location.pathname === `/seller/leads` ? true : false,
    },
    {
      name: 'Messages',
      href: `/seller/messages`,
      icon: ChatBubbleBottomCenterIcon,
      current: window.location.pathname === `/seller/messages` ? true : false,
    },
    // {
    //     name: 'My Tasks',
    //     href: `/seller/tasks`,
    //     icon: ListBulletIcon,
    //     current: window.location.pathname === `/seller/tasks` ? true : false,
    //     isSeller: true,
    // },
  ]

  const buyerNavigation = [
    {
      name: 'Dashboard',
      href: `/buyer/${user?.username}`,
      icon: HomeIcon,
      current:
        window.location.pathname === `/buyer/${user?.username}` ? true : false,
    },
    {
      name: 'Projects',
      href: `/buyer/projects`,
      icon: FolderIcon,
      current: window.location.pathname === `/buyer/projects` ? true : false,
    },
    {
      name: 'Messages',
      href: `/buyer/messages`,
      icon: ChatBubbleBottomCenterIcon,
      current: window.location.pathname === `/buyer/messages` ? true : false,
    },
  ]

  const adminNavigation = [
    {
      name: 'Dashboard',
      href: `/admin/`,
      icon: HomeIcon,
      current: window.location.pathname === `/admin/` ? true : false,
    },
    {
      name: 'Projects',
      href: `/admin/projects`,
      icon: EnvelopeIcon,
      current: window.location.pathname === `/admin/projects` ? true : false,
    },
    {
      name: 'Leads',
      href: `/admin/leads`,
      icon: InboxIcon,
      current: window.location.pathname === `/admin/leads` ? true : false,
    },
    {
      name: 'Messages',
      href: `/admin/messages`,
      icon: ChatBubbleBottomCenterIcon,
      current: window.location.pathname === `/admin/messages` ? true : false,
    },
    {
      name: 'Platforms',
      href: `/admin/platforms`,
      icon: FolderOpenIcon,
      current: window.location.pathname === `/admin/platforms` ? true : false,
    },
    {
      name: 'Users',
      href: '/admin/users',
      icon: UserGroupIcon,
      current: window.location.pathname === '/admin/users' ? true : false,
    },
    {
      name: 'Roles',
      href: '/admin/roles',
      icon: UserCircleIcon,
      current: window.location.pathname === '/admin/roles' ? true : false,
    },
    {
      name: 'Permissions',
      href: '/admin/permissions',
      icon: SignalIcon,
      current: window.location.pathname === '/admin/permissions' ? true : false,
    },
  ]

  if (isAdmin === true) {
    return adminNavigation.map((item) => (
      <li key={item.name}>
        <NavLink
          to={item.href}
          className={classNames(
            item.current
              ? 'bg-gray-50 text-purple-600 dark:bg-purple-900 dark:text-white'
              : 'text-gray-700 dark:text-white hover:text-purple-600 hover:bg-gray-50 dark:hover:bg-purple-900',
            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
          )}
        >
          <item.icon
            className={classNames(
              item.current
                ? 'text-purple-600 dark:text-white'
                : 'text-gray-400 group-hover:text-purple-600 dark:group-hover:text-white',
              'h-6 w-6 shrink-0'
            )}
            aria-hidden="true"
          />
          {item.name}
        </NavLink>
      </li>
    ))
  } else if (isSeller === true) {
    return sellerNavigation.map((item) => (
      <li key={item.name}>
        <NavLink
          to={item.href}
          className={classNames(
            item.current
              ? 'bg-gray-50 text-purple-600 dark:bg-purple-900 dark:text-white'
              : 'text-gray-700 dark:text-white hover:text-purple-600 hover:bg-gray-50 dark:hover:bg-purple-900',
            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
          )}
        >
          <item.icon
            className={classNames(
              item.current
                ? 'text-purple-600 dark:text-white'
                : 'text-gray-400 group-hover:text-purple-600 dark:group-hover:text-white',
              'h-6 w-6 shrink-0'
            )}
            aria-hidden="true"
          />
          {item.name}
        </NavLink>
      </li>
    ))
  } else {
    return buyerNavigation.map((item) => (
      <li key={item.name}>
        <NavLink
          to={item.href}
          className={classNames(
            item.current
              ? 'bg-gray-50 text-purple-600 dark:bg-purple-900 dark:text-white'
              : 'text-gray-700 dark:text-white hover:text-purple-600 hover:bg-gray-50 dark:hover:bg-purple-900',
            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
          )}
        >
          <item.icon
            className={classNames(
              item.current
                ? 'text-purple-600 dark:text-white'
                : 'text-gray-400 group-hover:text-purple-600 dark:group-hover:text-white',
              'h-6 w-6 shrink-0'
            )}
            aria-hidden="true"
          />
          {item.name}
        </NavLink>
      </li>
    ))
  }
}
export default NavLinks

import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

const Form = () => {
  const step = useSelector(
    (state: RootState) => state.buyerProjects.currentStep
  )

  if (step === 1) {
    return <StepOne />
  } else if (step === 2) {
    return <StepTwo />
  } else {
    return <StepThree />
  }
}
export default Form

import { Bars3Icon, BellIcon } from '@heroicons/react/24/outline'
import {
  ChevronDownIcon,
  EllipsisVerticalIcon,
  PlusIcon,
} from '@heroicons/react/20/solid'
import { Fragment, useEffect } from 'react'
import { Menu, Switch, Transition } from '@headlessui/react'
import { Link, NavLink } from 'react-router-dom'
import { logout, reset } from '../../../store/auth/authReducer'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import Gravatar from 'react-gravatar'
import { RootState } from '../../../store'
import { setIsDark } from '../../../store/theme/themeReducer'
import SearchForm from '../../SearchForm/Index'

const userNavigation = [
  { name: 'Subscription', href: '/buyer/subscription' },
  { name: 'Buyer Profile', href: '/buyer/profile' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TopBar = ({ sidebarOpen, setSidebarOpen, isSeller }) => {
  const { isDark } = useSelector((state: RootState) => state.theme)
  const { user } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsDark(isDark))
  }, [isDark])

  const handleLogout = () => {
    dispatch(logout())
    toast.success('Logged out successfully')
    dispatch(reset())
  }

  return (
    <>
      <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white dark:bg-gray-950 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 dark:text-white lg:hidden"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <SearchForm />
          <div className="flex items-center gap-x-4 lg:gap-x-6">
            {isSeller ? (
              <Link
                to={`/seller/tasks/new`}
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
              >
                <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              </Link>
            ) : (
              <Link
                to={`/buyer/projects/new`}
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
              >
                <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              </Link>
            )}

            <Switch
              checked={isDark}
              onChange={() => dispatch(setIsDark(!isDark))}
              className={classNames(
                isDark ? 'bg-purple-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2'
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                className={classNames(
                  isDark ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              >
                <span
                  className={classNames(
                    isDark
                      ? 'opacity-0 duration-100 ease-out'
                      : 'opacity-100 duration-200 ease-in',
                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                  )}
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-3 w-3 text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                    />
                  </svg>
                </span>
                <span
                  className={classNames(
                    isDark
                      ? 'opacity-100 duration-200 ease-in'
                      : 'opacity-0 duration-100 ease-out',
                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                  )}
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-3 w-3 text-purple-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                    />
                  </svg>
                </span>
              </span>
            </Switch>

            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
              aria-hidden="true"
            />

            {/* Profile dropdown */}

            <Menu as="div" className="relative">
              <Menu.Button className="-m-1.5 flex items-center p-1.5">
                <span className="sr-only">Open user menu</span>
                <span className="relative inline-block">
                  <Gravatar
                    className="h-8 w-8 rounded-full bg-gray-50"
                    email={user?.email}
                    rating="pg"
                  />
                  {/* <span className="absolute bottom-0 right-0 block h-3 w-3 rounded-full bg-gray-300 ring-2 ring-white" /> */}
                  <span className="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full bg-green-400 ring-2 ring-white" />
                </span>
                <span className="hidden lg:flex lg:items-center">
                  <span
                    className="ml-4 text-sm font-semibold leading-6 dark:text-white text-gray-900"
                    aria-hidden="true"
                  >
                    {user?.first_name}
                  </span>
                  <ChevronDownIcon
                    className="ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-44 origin-top-right rounded-md bg-white dark:bg-gray-950 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  {user?.role === 'ROLE_SELLER' &&
                    window.location.pathname.startsWith(`/seller/`) && (
                      <>
                        <Menu.Item>
                          <div className="px-4 py-2 text-gray-900 dark:text-white">
                            <span>Balance:</span> <span>$0.00</span>{' '}
                            {/* Add balance */}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink
                              to="/add-credit" // This is the new Credit button
                              className={classNames(
                                active
                                  ? 'bg-gray-100 dark:bg-gray-950 dark:text-white dark:hover:bg-purple-600 text-gray-900'
                                  : '',
                                'block px-3 py-1 text-sm leading-6 dark:text-white text-gray-900'
                              )}
                            >
                              Add Credit
                            </NavLink>
                          )}
                        </Menu.Item>
                      </>
                    )}
                  {user &&
                  user?.stripe_seller?.payouts_enabled &&
                  !window.location.pathname.startsWith(`/seller/`) ? (
                    <Menu.Item>
                      {({ active }) => (
                        <NavLink
                          to={`/seller/${user?.username}`}
                          className={classNames(
                            active
                              ? 'bg-gray-100 dark:bg-gray-950 dark:text-white dark:hover:bg-purple-600 text-gray-900'
                              : '',
                            'block px-3 py-1 text-sm leading-6 dark:text-white text-gray-900'
                          )}
                        >
                          Switch to Seller
                        </NavLink>
                      )}
                    </Menu.Item>
                  ) : null}

                  {window.location.pathname.startsWith(`/seller/`) ? (
                    <Menu.Item>
                      {({ active }) => (
                        <NavLink
                          to={`/buyer/${user?.username}`}
                          className={classNames(
                            active
                              ? 'bg-gray-100 dark:bg-gray-950 dark:text-white dark:hover:bg-purple-600 text-gray-900'
                              : '',
                            'block px-3 py-1 text-sm leading-6 dark:text-white text-gray-900'
                          )}
                        >
                          Switch to Buyer
                        </NavLink>
                      )}
                    </Menu.Item>
                  ) : null}

                  {user?.role === 'ROLE_SELLER' ||
                  user?.role === 'ROLE_ADMIN' ? (
                    <Menu.Item>
                      {({ active }) => (
                        <NavLink
                          to={`/seller/stripe/connect`}
                          className={classNames(
                            active
                              ? 'bg-gray-100 dark:bg-gray-950 dark:text-white dark:hover:bg-purple-600 text-gray-900'
                              : '',
                            'block px-3 py-1 text-sm leading-6 dark:text-white text-gray-900'
                          )}
                        >
                          Payout Dashboard
                        </NavLink>
                      )}
                    </Menu.Item>
                  ) : (
                    <Menu.Item>
                      {({ active }) => (
                        <NavLink
                          to={`/seller/stripe/connect`}
                          className={classNames(
                            active
                              ? 'bg-gray-100 dark:bg-gray-950 dark:text-white dark:hover:bg-purple-600 text-gray-900'
                              : '',
                            'block px-3 py-1 text-sm leading-6 dark:text-white text-gray-900'
                          )}
                        >
                          Seller Signup
                        </NavLink>
                      )}
                    </Menu.Item>
                  )}

                  {user?.role === 'ROLE_ADMIN' ? (
                    <Menu.Item>
                      {({ active }) => (
                        <NavLink
                          to={`/admin/`}
                          className={classNames(
                            active
                              ? 'bg-gray-100 dark:bg-gray-950 dark:text-white dark:hover:bg-purple-600 text-gray-900'
                              : '',
                            'block px-3 py-1 text-sm leading-6 dark:text-white text-gray-900'
                          )}
                        >
                          Switch to Admin
                        </NavLink>
                      )}
                    </Menu.Item>
                  ) : null}

                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link
                          to={item.href}
                          className={classNames(
                            active
                              ? 'bg-gray-100 dark:bg-gray-950 dark:text-white dark:hover:bg-purple-600 text-gray-900'
                              : '',
                            'block px-3 py-1 text-sm leading-6 dark:text-white text-gray-900'
                          )}
                        >
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>

            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="-my-2 flex items-center rounded-full bg-transparent p-2 text-gray-900 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5 dark:text-white"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          onClick={handleLogout}
                          to="#"
                          className={classNames(
                            active
                              ? 'bg-gray-100 dark:bg-gray-950 dark:text-white dark:hover:bg-purple-600 text-gray-900'
                              : 'text-gray-700 dark:text-white',
                            'flex justify-between px-4 py-2 text-sm'
                          )}
                        >
                          <span>Sign Out</span>
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  )
}
export default TopBar

import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'

function CookiePolicy() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookie, setCookie] = useCookies(['cookieConsent'])

  const handleCookieConsent = () => {
    setCookie('cookieConsent', true, {
      path: '/',
      maxAge: 31536000,
      sameSite: true,
    })
  }

  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6 z-10">
      <div className="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
        <p className="text-sm leading-6 text-gray-900">
          This website uses cookies to supplement a balanced diet and provide a
          much deserved reward to the senses after consuming bland but
          nutritious meals. Accepting our cookies is optional but recommended,
          as they are delicious. See our{' '}
          <a href="/privacy-policy" className="font-semibold text-purple-600">
            cookie policy
          </a>
          .
        </p>
        <div className="mt-4 flex items-center gap-x-5">
          <button
            onClick={handleCookieConsent}
            type="button"
            className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Accept all
          </button>
          <Link
            to="/privacy-policy"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CookiePolicy
